<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">OUR DEFAQTO STAR RATINGS</h1>
            </div>

            <div class="box b-shdw-3">

                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
                
                <div class="content">
                    <p>
                        We're absolutely delighted to have achieved a 5 Star Rating for both our Gold and Platinum Cover Levels. As a well-known, independent accreditation, our Defaqto Ratings add extra reassurance and credibility, so that you, our customer, can trust the high-quality products we offer.
                    </p>
                </div>
                <div>
                    <p>
                        Here are the Star Ratings for our Cover Levels:
                    </p>
                </div>
                <hr>
                <div>
                    <h2><strong>Platinum Cover Level:</strong></h2>
                    <p>
                        <ul>
                            <li>5 Star Rating</li>
                            <li>Comprehensive level of cover</li>
                            <li>£10,000 Cancellation Cover</li>
                            <li>£10 Million Medical Expenses</li>
                            <li>£5,000 Baggage and Personal Belongings</li>
                        </ul>
                    </p>
                    <img src="/img/defaqto.png" alt="defaqto expert rated logo" />
                </div>
                <hr>
                <div>
                    <h2><strong>Gold Cover Level:</strong></h2>
                    <p>
                        <ul>
                            <li>5 Star Rating</li>
                            <li>Comprehensive level of cover</li>
                            <li>£5,500 Cancellation Cover</li>
                            <li>£10 Million Medical Expenses</li>
                            <li>£3,000 Baggage and Personal Belongings</li>
                        </ul>
                    </p>
                    <img src="/img/defaqto.png" alt="defaqto expert rated logo" />
                </div>
                <hr>
                <div>
                    <h2><strong>Silver Cover Level:</strong></h2>
                    <p>
                        <ul>
                            <li>Our budget-friendly cover level</li>
                            <li>Visit <a href="https://www.defaqto.com/star-ratings/travel/travel-insurance-annual/" target="_blank">Defaqto</a> to check the Defaqto Star Rating for this policy</li>
                            <li>£1,500 Cancellation Cover</li>
                            <li>£5 Million Emergency Medical Expenses</li>
                            <li>£1,500 Baggage and Personal Belongings</li>
                        </ul>
                    </p>
                </div>
                <hr>
                <div>
                    <h2><strong>Cruise Cover Level:</strong></h2>
                    <p>
                        <ul>
                            <li>Specialist Cruise Cover with Cruise Specific Benefits</li>
                            <li>Visit <a href="https://www.defaqto.com/star-ratings/travel/travel-insurance-annual/" target="_blank">Defaqto</a> to check the Defaqto Star Rating for this policy</li>
                            <li>Up to £10,000 Cancellation Cover</li>
                            <li>£10 Million Emergency Medical Expenses</li>
                            <li>£2,000 Baggage and Personal Belongings</li>
                        </ul>
                    </p>
                </div>
                <hr>
                <div>
                    <p>
                        <a href="https://www.covered2go.co.uk/get-quote">Get A Quote for Defaqto 5 Star rated* Travel Insurance.</a>
                        <br>
                        * Gold and Platinum policies only.
                    </p>
                </div>
                <hr>
                <div>
                    <h2><strong>Who are Defaqto?</strong></h2>
                    <p>
                        Defaqto is a leading financial information, ratings and fintech business. Its unbiased product information, provided as Star Ratings, helps consumers, financial institutions and financial advisers make better informed decisions.
                    </p>
                    <p>
                        For over 25 years, Defaqto's experts have been collecting up-to-date information on thousands of financial products. By researching and analysing every product on the market, they've created an easy and reliable way to compare them.
                    </p>
                </div>
                <div>
                    <h2><strong>What is a Defaqto Star Rating?</strong></h2>
                    <p>
                        Star Ratings show the quality and comprehensiveness of the features and benefits financial products. Their experts help consumers see at a glance where a product sits in the market, Star Ratings give each a rating of 1 to 5. A 1 Star Rating indicates a basic product with a low level of features and benefits, while a 5 Star Rating provides one of the highest quality offerings on the market.
                    </p>
                    <p>
                        Defaqto independently rates products across more than 60 categories.
                    </p>
                    <blockquote>
                        "At Defaqto we believe that consumers should base their financial decisions on more than just the price of financial products. People should think about what they need and then buy a product that meets their requirements. It can have serious consequences when, for example, you take out an insurance policy and find that you're not covered when you're in an emergency."
                    </blockquote>
                    <br>
                    <blockquote>
                        "Star Ratings help consumers see, at a glance, where a product sits in the market based on the comprehensiveness of features and benefits offered. For each product or insurance policy we assess between 40 and 100 features or benefits as well as the terms and conditions"
                    </blockquote>
                    <p>
                        Which ultimately means that our customers can be reassured that our travel insurance policies offer some of the most wide-ranging level of features and benefits on the market.
                    </p>
                    <p>
                        Find out more at <a href="https://defaqto.com/star-ratings">Defaqto Star Ratings</a>.
                    </p>
                </div>
                <hr>
                <div>
                    <iframe src="https://player.vimeo.com/video/454033078?h=c3c384eb5d" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <p>
                        <a href="https://vimeo.com/454033078">Defaqto: Star Ratings</a> from <a href="https://vimeo.com/defaqto">Defaqto</a> on <a href="https://vimeo.com">Vimeo</a>.
                    </p>
                </div>
                <br><br>
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
                <br><br>
                <info-boxes />
            </div>
        </div>
    </transition>
</template>

<script>

    import InfoBoxes from "../components/common/InfoBoxes";
    import InfoButtonOptions from "@/components/common/InfoButtonOptions";

    export default {
        name: "Defaqto",
        components: {
            InfoBoxes,
            InfoButtonOptions
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/styles/views/info-page";
    .level-item {
        margin-bottom: 20px;
    }
    p {
        padding: 10px;
    }
    ul {
        margin-left: 20px;
    }
    li {
        list-style-type: disc;
    }
    blockquote {
        margin-left: 50px;
    }
</style>